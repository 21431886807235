.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.webchat__wrapper {
  max-height: 70%;
  position: fixed;
  bottom: 80px;
  height: 100vh;
  width: 100%;
  box-shadow: 0px 31px 48px -31px #41396a26;
  overflow : hidden;
  border-radius: 0px 0px 0px 0px;
  max-width: 440px;
  border-radius: 20px;
}

.prompt-container {
  width: 100%;
  background-color: white;
  padding-right: 10px;
  padding-left: 10px;
  text-align: left;
}

.chat-prompts {
  margin: 4px 0px;
  flex-wrap: nowrap;
  padding: 2px 8px;
  display: flex;
  overflow-x: auto;
  max-width: 100%;
  scrollbar-width: none;
  flex-direction: row-reverse;
}

.chat-prompts button {
  flex: 0 0 auto;
  margin-left: 10px;
  font-size: small;
  height: 32px;
}

.chat-prompt-button {
  color: black;
  background: #FFFFFF;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 4px 12px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  border: 1px solid #038C8C;
  box-shadow: 4px 4px 6px 2px #2B374F1F;
  border: none;
  margin: 4px 0px 8px 4px;
  font-weight: 500;
}


.input-field {
  height: 44px;
  border: none;
  color: rgb(60, 66, 87);
  background-color: #F7F9FC;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 4px 8px;
  min-height: 28px;
  vertical-align: middle;
  transition: background-color .24s,box-shadow .24s;
  transition-property: background-color, box-shadow;
  transition-duration: 0.24s, 0.24s;
  transition-timing-function: ease, ease;
  transition-delay: 0s, 0s;
  }   


  .option-button {
    background: #5E5DF0;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 12px 12px; 
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    border: 0;
    margin: 5px 0px 5px;
    width: 100%;
    height: 48px;
    border-radius: 6px;
}

.option-button:hover{
    opacity: 0.95
}

.option-button-home {
  background: #F7F9FC;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 12px 12px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  border: 0;
  margin: 12px 0px 12px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

.option-button-home:hover {
    background:  #F7F7F7;
}


.link-style{
  color: #5E5DF0;
  text-decoration: none;
}

.ticket-icon-wrapper {
  display: flex;
  flex-direction: column;
}

.ticket-summary-wrapper {
  margin-top: 10px;
}


.ticket-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

button:disabled {
  background-color: #ddd !important; /* Gray when disabled */
  color: #666 !important; /* Lighter text when disabled */
  cursor: not-allowed !important;
}