.footer {
  font-size: 12px;
  color: #888;
  text-align: center;
  padding: 5px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer-sec{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
}

.footer-img{
  width: 18.6px;
  height: 18.6px;
}

.footer-enjo{
  color: #1A2642;
  font-weight: 600;
}