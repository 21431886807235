.webchat__toggler {
    position: fixed;
    bottom: 0px;
    outline: none;
    border: none;
    height: 56px;
    width: 56px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.2s ease;
    box-shadow: 0px 31px 48px -31px #41396a26;
}


.webchat__toggler_text {
    position: fixed;
    bottom: 0px;
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: none;
    border: none;
    display: flex;
    border-radius: 10px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background: white;
    transition: all 0.2s ease;
    box-shadow: 0px 31px 48px -31px #41396a26;
    transition: transform 0.1s ease-in-out;

}