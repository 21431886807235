.message-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 50vh;
    overflow: scroll;
  }
  
  .message-item {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
  }
  
  .message-item:hover {
    background-color: #f9f9f9;
  }
  
  .message-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  
  .icon-license {
    color: white;
  }
  
  .icon-account {
    background-color: #8bc34a; /* Account icon background color */
    color: white;
  }
  
  .message-content {
    flex-grow: 1;
    font-family: Inter, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
  
  .message-title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
    display: flex;
  }
  
  .message-description {
    font-size: 14px;
    color: #888;
  }
  
  .message-arrow {
    font-size: 18px;
    color: #888;
  }
  

  .conversation-time {
    font-size: 10px;
    margin-left: 8px;
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.45);
  }

  .start_conversation_home {
    background: #038C8C;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
    /* font-size: 14px; */
    font-weight: 600;
    line-height: 24px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 24px 12px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    border: 0;
    margin: 6px 0px 30px;
    width: 90%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    position: absolute;
    bottom: 14%;
}