.typing {
    display: inline-block;
  }
  
  .dot {
    display: inline-block;
    animation: blink 1.4s infinite both;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }

  .dot:nth-child(4) {
    animation-delay: 0.6s;
  }

  .dot:nth-child(5) {
    animation-delay: 0.8s;
  }
  
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .timestamp {
    visibility: hidden;
  }
  
  .message-container:hover .timestamp {
    font-size: 10px;
    visibility: visible;
  }

  .create-ticket-button-wrapper {
    display: flex;
    border-radius: 4px;
    flex-direction: column;
  }

  .create-ticket-button {
    border-radius: 6px;
    font-size: 11px;
    cursor: pointer;
    transition: backgroundColor 0.3s;
    padding: 1px 8px;
  }

  .ticket {
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    color: #333;
    margin: 16px;
  }
  .ticket-header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
  }
  .ticket-icon {
      color: #FFD700;
  }
  .ticket-id {
      color: #7f8cfc;
      text-decoration: none;
      font-weight: bold;
  }
  .ticket-title {
      flex: 1;
      font-weight: normal;
  }
  .ticket-details p {
      margin: 4px 0;
  }
  .ticket-actions {
      display: flex;
      gap: 8px;
      margin-top: 16px;
      flex-wrap: wrap;
  }

  .create-ticket-button {
    border-radius: 6px;
    font-size: 11px;
    cursor: pointer;
    transition: background-color 0.3s;
    padding: 1px 8px;
    height: 40px;
    width: 100%;
    margin-top: 10px;
    background: #038C8C;
    color: white;
    border: none;

  }
  .create-ticket-button:hover {
    opacity: 0.95
  }