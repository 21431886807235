.chat-container {
   width: 420px;
   height: 80vh;
   border-radius: 16px;
}


/* mobile screen full width */
@media screen and (max-width: 600px) {
  .chat-container {
    width: 95vw;
    height: 91vh;
  }
}